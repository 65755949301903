<template>
  <el-container class="shopList">
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
       <el-breadcrumb-item  to="/admin/menu">菜单列表</el-breadcrumb-item>
       <el-breadcrumb-item>按钮管理</el-breadcrumb-item>
    </el-breadcrumb>
        <div><el-button  icon="el-icon-plus"
          type="primary"  @click="Add()" size="medium">新增按钮</el-button>
            <el-button class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="">
    <div class="box list">
       <el-table border :data="tableData" style="width: 100%">
        <el-table-column type="index"  width="60"   label="序号" align='center' ></el-table-column>
        <el-table-column prop="menubtn_id" label="按钮ID" min-width="60"  :show-overflow-tooltip="true" align='center'></el-table-column>
        <el-table-column prop="menu_id" label="菜单ID" min-width="60"  :show-overflow-tooltip="true" align='center'></el-table-column>
        <el-table-column prop="menubtn_title" min-width="80" :show-overflow-tooltip="true"  label="按钮名称" align='center'></el-table-column>
       
        <el-table-column label="操作" width="150" fixed="right"  align='center'>
          <template slot-scope="scope">
               <el-button  type="text" class="orange disib"  size="mini"  @click="goUpdate(scope.row.menubtn_id)">编辑</el-button>
           <el-button type="text" class="red" size="medium" @click="godelete(scope.row.menubtn_id,scope.$index)">删除</el-button>
         
         </template>
        </el-table-column>
      </el-table>
    </div> 
    </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { mapState } from 'vuex'
import { session} from "../../../util/util";
import {imageUrl} from '../../../config/index'
export default {
  data () {
    return {
        imageUrl:imageUrl,
      page: {
      menu_id:''
      },
      currentPage: 1,
      total: 0,
      tableData:[],
       
    }
  },
   created () {
        this.page.menu_id =session.get('params1').id?String(session.get('params1').id):'';
    this.init(this.page)
  },
  methods: {
    init (params) {
      axios.get('/pc/menu-button/all', params).then((v) => {
        this.tableData=v.data;
      })
    },
    //编辑按钮
    goUpdate(index){
      session.set('params2',{id:this.page.menu_id,id1:index,name:''})
      this.$router.push({
        name: "BtnAdd"
      });
    },
    //新增按钮
    Add(){
      session.set('params2',{id:this.page.menu_id,id1:'',name:''})
        this.$router.push({
        name: "BtnAdd"
      });
    },
   godelete(val,index){
     this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
           axios.delete('/pc/menu-button/delete', {menubtn_id:String(val)}).then((v) => {
                this.tableData.splice(index, 1)
           })
        })
  }
  }
}
</script>
<style lang="scss" scoped>
.shopList{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
}

</style>
